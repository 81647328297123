.text-typer {
  display: inline-block;
}

.cursor {
  background: black;
  width: 2px;
  height: 1em;
  display: inline-block;
  margin-left: 2px;
  animation: blink 0.5s infinite;
}

.text-typer.done-typing .cursor {
  animation: none;
  background: transparent;
}

@keyframes blink {
  0% {
    background: black;
  }
  50% {
    background: transparent;
  }
  100% {
    background: black;
  }
}
