.min-loader g path {
  fill: #ffffff;
}

.chat-box {
  &:hover {
    background-color: #fdfdfd !important;

    .explain-box {
      opacity: 1 !important;
    }

    .audio-box {
      opacity: 1 !important;
    }
  }
}

.line-break {
  //white-space: pre-wrap;
  //word-break: break-word;
}
