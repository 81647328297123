.drawer {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100vh;

  width: 100%;

  overflow: auto;

  background-color: rgba(153, 153, 153, 0.6);
  display: none;
  opacity: 0;
  transition: opacity;

  flex-direction: row;

  .drawer-empty {
    flex-grow: 1;
  }

  .drawer-content {
    flex-grow: 0;
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    width: 100%;
    max-width: 480px;
    //height: 100vh;
    height: 100%;
    overflow: auto;
    transition: transform 0.3s ease-out;
    transform: translateX(100%);
  }
}

.drawer.open {
  .drawer-content {
    transform: translateX(0);
  }

  display: flex;
  opacity: 1;
  transition: opacity;
}
