.rooms-drawer {
  //position: fixed;
  //left: 0;
  //z-index: 100;
  //top: 110px;
  height: calc(100% + 60px);
  background: #fff;
  //width: 250px;
  //transition: transform 0.3s ease-out;
  //transform: translateX(-100%);
  //overflow: auto;
  //overflow: hidden;
  @media only screen and (max-width: 768px) {
    top: 73px;
  }
}

.rooms-drawer.open {
  //transform: translateX(0);
}
