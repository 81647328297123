.line-break {
  p {
    //white-space: pre-wrap;
    //word-break: break-word;
    //margin-top: 8px;
    //margin-bottom: 8px;
  }
}

.blog-pre {
  //margin-bottom: 3em !important;
  position: relative;
  margin-right: 40px;
  background-color: rgb(250, 250, 250);
  border: 1px solid #edf1f6;
  border-radius: 6px;
  padding: 4px;

  &:hover {
    .code-copy-btn {
      opacity: 1;
    }
  }
}

.code-copy-btn {
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  opacity: 0;

  &:hover {
    transform: scale(1.1);
    opacity: 0.9;
  }
}

.boldcode {
  font-weight: bold;
}
